import React from 'react';

const Positions = () => {
    return (
        <section className="wrapper bg-light">
            <div className="container py-14 py-md-16">
                <div className="row text-center">
                    <div className="col-xl-10 mx-auto">
                        <h2 className="fs-15 text-uppercase text-primary mb-3">Job Positions</h2>
                        <h3 className="display-4 mb-10 px-xxl-15">We’re always searching for amazing people to join our team. Take a look at our current openings.</h3>
                    </div>
                </div>
                <div className="row gy-6">
                    <div className="col-md-6 col-lg-4">
                        <a href="#" className="card shadow-lg lift h-100">
                            <div className="card-body p-5 d-flex flex-row">
                                <div>
                                    <span className="avatar bg-red text-white w-11 h-11 fs-20 me-4">SD</span>
                                </div>
                                <div>
                                    <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">Full Time</span>
                                    <h4 className="mb-1">Senior Graphic Designer</h4>
                                    <p className="mb-0 text-body">San Francisco, US</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <a href="#" className="card shadow-lg lift h-100">
                            <div className="card-body p-5 d-flex flex-row">
                                <div>
                                    <span className="avatar bg-green text-white w-11 h-11 fs-20 me-4">UX</span>
                                </div>
                                <div>
                                    <span className="badge bg-pale-aqua text-aqua rounded py-1 mb-2">Remote</span>
                                    <h4 className="mb-1">UI/UX Designer</h4>
                                    <p className="mb-0 text-body">Anywhere</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <a href="#" className="card shadow-lg lift h-100">
                            <div className="card-body p-5 d-flex flex-row">
                                <div>
                                    <span className="avatar bg-yellow text-white w-11 h-11 fs-20 me-4">AN</span>
                                </div>
                                <div>
                                    <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">Full Time</span>
                                    <h4 className="mb-1">Multimedia Artist &amp; Animator</h4>
                                    <p className="mb-0 text-body">Birmingham, UK</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <a href="#" className="card shadow-lg lift h-100">
                            <div className="card-body p-5 d-flex flex-row">
                                <div>
                                    <span className="avatar bg-purple text-white w-11 h-11 fs-20 me-4">FD</span>
                                </div>
                                <div>
                                    <span className="badge bg-pale-violet text-violet rounded py-1 mb-2">Part Time</span>
                                    <h4 className="mb-1">Front End Developer</h4>
                                    <p className="mb-0 text-body">Sydney, AU</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <a href="#" className="card shadow-lg lift h-100">
                            <div className="card-body p-5 d-flex flex-row">
                                <div>
                                    <span className="avatar bg-orange text-white w-11 h-11 fs-20 me-4">MD</span>
                                </div>
                                <div>
                                    <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">Full Time</span>
                                    <h4 className="mb-1">Mobile Developer</h4>
                                    <p className="mb-0 text-body">San Francisco, US</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <a href="#" className="card shadow-lg lift h-100">
                            <div className="card-body p-5 d-flex flex-row">
                                <div>
                                    <span className="avatar bg-pink text-white w-11 h-11 fs-20 me-4">ND</span>
                                </div>
                                <div>
                                    <span className="badge bg-pale-blue text-blue rounded py-1 mb-2">Full Time</span>
                                    <h4 className="mb-1">.NET Developer</h4>
                                    <p className="mb-0 text-body">Manchester, UK</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="row mt-11">
                    <div className="col-lg-6 col-xl-5 mx-auto text-center">
                        <h2 className="display-6 mb-3">Can't find the right position?</h2>
                        <p className="lead mb-5 px-md-16 px-lg-3">Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur.</p>
                        <a href="#" className="btn btn-primary rounded-pill">Get in Touch</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Positions;
