import React from 'react';

const OurInitiatives = () => {
    return (
        <>
            <section className="wrapper bg-gray mt-15">
                <div className='container py-10'>
                    <div className="row gx-lg-8 gx-xl-12 gy-6 mb-0 align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <div className='card'>
                                <div className='card-body px-1 py-1'>
                                    <div className="player rounded-0" data-plyr-provider="youtube" data-plyr-embed-id="nb8ulzBMb5Q"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="fs-16 text-uppercase text-muted mb-1">Why Study With Us?</h2>
                            <h3 className="display-5 ls-xs mb-3">Our programs are  <span className="underline-3 style-2 yellow">affordable, </span><br />intensive, and short.</h3>
                            <p>Participants enhance their skills through interactive feedback sessions and assessments. We also offer cost-effective options, including free programs, to ensure our courses are accessible to everyone.</p>
                            <div id='method-of-teaching' className="">
                                <div className="accordion accordion-wrapper" id="accordionExample">
                                    <div className="card plain accordion-item">
                                        <div className="card-header" id="headingOne">
                                            <button className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"> Live Classes </button>
                                        </div>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>At Codevixens, we believe in the power of live, interactive learning. Our instructors lead engaging sessions ranging from 1 to 2 hours - 30 minutes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card plain accordion-item">
                                        <div className="card-header" id="headingTwo">
                                            <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo"> Personalised Learning </button>
                                        </div>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>At Codevixens, we understand that everyone learns differently. That's why we go beyond a one-size-fits-all approach. This means you'll receive guidance tailored to your learning style and pace, allowing you to ask questions, get feedback, and truly grasp the material. </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card plain accordion-item">
                                        <div className="card-header" id="headingThree">
                                            <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> Feedback Sessions: Quizzes & Assessments </button>
                                        </div>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                            <div className="card-body">
                                                <p className='mb-0'>Our program incorporates regular assessments to solidify your understanding of the material. Why? We believe learning goes beyond lectures. These assessments provide opportunities to receive valuable feedback and personalized guidance from instructors. This interactive approach allows you to track your progress, identify areas for improvement, and ultimately, master the skills you need to succeed in your upskilling journey</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="tuition-fees" className="wrapper bg-light">
                <div className="container pt-13 pt-md-14 pb-7 pb-md-10">
                    <div className="row text-center">
                        <div className="col-md-11 col-lg-10 col-xl-9 mx-auto">
                            <h2 className="fs-16 text-uppercase text-muted mb-3">Our Tuition Fee Pricing</h2>
                            <h3 className="display-4 mb-2 px-xxl-10">Affordable Technical Education ✅ <strike> <br /> Breaking the Bank</strike> ❌</h3>
                            <p className="lead fs-19 mb-10 px-xxl-15">At Codevixens, we believe a rewarding <span className="underline">tech career shouldn't break the bank. </span> <br />That’s why we’ve partnered with organizations and individuals to provide <strong>scholarships for our paid programs</strong>, subsidizing tuition fees by up to 90%.</p>
                        </div>
                    </div>
                    <div className="table-responsive mt-10 mt-lg-0">
                        <table className="table table-borderless table-striped text-center">
                            <thead>
                                <tr>
                                    <th className="w-25"></th>
                                    <th>
                                        <div className="h4 mb-1">Tuition Fee</div>
                                        {/* <div className="fs-15 fw-normal text-secondary">$9 / Monthly</div> */}
                                    </th>
                                    <th>
                                        <div className="h4 mb-1">Duration</div>
                                        {/* <div className="fs-15 fw-normal text-secondary">$19 / Monthly</div> */}
                                    </th>
                                    <th>
                                        <div className="h4 mb-1">Enrollment Process</div>
                                        {/* <div className="fs-15 fw-normal text-secondary">$29 / Monthly</div> */}
                                    </th>
                                    <th>
                                        <div className="h4 mb-1">Benefits</div>
                                        {/* <div className="fs-15 fw-normal text-secondary">$49 / Monthly</div> */}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="option text-start">Academy Schools</td>
                                    <td> <strong>Up to 86.7% scholarship</strong> <br/> <strike>₦150,000</strike> ❌ ₦30,000 (Max) ✅  <br/>(35USD)</td>
                                    <td>7 Weeks <br/>[2x Live Class <br/>Every Week]</td>
                                    <td>Online Application & Review, Tuition Fee Payment.</td>
                                    <td>Expert-led training, <br />Feedback Sessions, <br />Certicicate of Completion</td>
                                </tr>
                                <tr>
                                    <td className="option text-start">Felllowship</td>
                                    <td><strong>80% scholarship</strong> <br/> <strike>₦250,000</strike> ❌ <br />₦50,000 ✅  (45USD)</td>
                                    <td>10 Weeks</td>
                                    <td>Online Application & Review, <br /> Interview, Entrance Evaluation Test, <br /> Fellowship Fee Payment.</td>
                                    <td>Mentorship, Job Readiness, <br />Enhanced Employability, <br />Real-world experience Gain, <br />Portfolio Building, Certificate of Completion</td>
                                </tr>
                                <tr>
                                    <td className="option text-start">20 Days Challenge</td>
                                    <td><strong className='badge badge-lg bg-dark fw-bold'>FREE</strong></td>
                                    <td>Min: 5 Days, <br />Max: 20 Days <br /> [Daily Intensive Challenges]</td>
                                    <td>Online Application & Review, <br /> Acceptance into Program.</td>
                                    <td>Certificate of Completion, <br />Skills Building, <br />Enhanced Growth, Portfolio Building</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* <div className="table-responsive mt-13">
                        <table className="table table-borderless table-striped text-center">
                            <thead>
                                <tr>
                                    <th className="w-25"></th>
                                    <th>
                                        <div className="h4 mb-1">Tuition Fee</div>
                                        <div className="fs-15 fw-normal text-secondary">$9 / Monthly</div>
                                    </th>
                                    <th>
                                        <div className="h4 mb-1">Duration</div>
                                        <div className="fs-15 fw-normal text-secondary">$19 / Monthly</div>
                                    </th>
                                    <th>
                                        <div className="h4 mb-1">Application Process</div>
                                        <div className="fs-15 fw-normal text-secondary">$29 / Monthly</div>
                                    </th>
                                    <th>
                                        <div className="h4 mb-1">Benefits</div>
                                        <div className="fs-15 fw-normal text-secondary">$49 / Monthly</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="option text-start">Storage</td>
                                    <td>100MB</td>
                                    <td>200MB</td>
                                    <td>500MB</td>
                                    <td>900MB</td>
                                </tr>
                                <tr>
                                    <td className="option text-start">Weekly Reports</td>
                                    <td>-</td>
                                    <td><i className="uil uil-check bg-pale-primary text-primary rounded-circle p-1"></i></td>
                                    <td><i className="uil uil-check bg-pale-primary text-primary rounded-circle p-1"></i></td>
                                    <td><i className="uil uil-check bg-pale-primary text-primary rounded-circle p-1"></i></td>
                                </tr>
                                <tr>
                                    <td className="option text-start">24/7 Support</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td><i className="uil uil-check bg-pale-primary text-primary rounded-circle p-1"></i></td>
                                    <td><i className="uil uil-check bg-pale-primary text-primary rounded-circle p-1"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div> */}
                </div>
            </section>

            <section className="wrapper bg-light mb-lg-22 mb-20">
                <div className="container pt-9 pt-lg-11 pb-14 pb-lg-6">
                    <div className="row mb-5">
                        <div className="col-md-10 col-xl-8 col-xxl-7 mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 447" data-inject-url="https://sandbox.elemisthemes.com/assets/img/icons/lineal/list.svg" className="svg-inject icon-svg icon-svg-md mb-4 text-navy">
                                <path className="lineal-stroke" d="M102.4 447C45.8 447 0 401.1 0 344.6s45.9-102.4 102.4-102.4c36.3 0 69.9 19.2 88.3 50.5 4.1 6.9 1.8 15.8-5.2 19.9-6.9 4.1-15.8 1.8-19.9-5.2-20.5-34.9-65.4-46.6-100.3-26.1s-46.6 65.4-26.1 100.3 65.4 46.6 100.3 26.1c10.2-6 18.8-14.3 25-24.3 4.3-6.8 13.2-8.9 20.1-4.6 6.8 4.3 8.9 13.2 4.6 20.1-18.7 30-51.5 48.2-86.8 48.1zm395.1-119.8H254.3c-8-.2-14.4-6.9-14.2-14.9.2-7.7 6.4-14 14.2-14.2h243.1c8 .2 14.4 6.9 14.2 14.9-.2 7.8-6.4 14-14.1 14.2zm-91.2 63.9h-152c-8-.2-14.4-6.9-14.2-14.9.2-7.7 6.4-14 14.2-14.2h152c8 .2 14.4 6.9 14.2 14.9-.2 7.7-6.5 14-14.2 14.2z"></path>
                                <circle className="lineal-fill" cx="102.4" cy="102.4" r="87.8"></circle>
                                <path className="lineal-stroke" d="M102.4 204.8C45.8 204.8 0 158.9 0 102.4S45.8 0 102.4 0s102.4 45.8 102.4 102.4c-.1 56.5-45.9 102.3-102.4 102.4zm0-175.7c-40.5 0-73.3 32.8-73.3 73.3s32.8 73.3 73.3 73.3 73.3-32.8 73.3-73.3c-.1-40.5-32.9-73.2-73.3-73.3zM497.5 85H254.3c-8-.2-14.4-6.9-14.2-14.9.2-7.7 6.4-14 14.2-14.2h243.1c8 .2 14.4 6.9 14.2 14.9-.2 7.8-6.4 14-14.1 14.2zm-91.2 63.8h-152c-8-.2-14.4-6.9-14.2-14.9.2-7.7 6.4-14 14.2-14.2h152c8 .2 14.4 6.9 14.2 14.9-.2 7.8-6.5 14-14.2 14.2z"></path>
                            </svg>
                            <h2 className="display-4 mb-4 px-lg-14">Applying for any of our educational programs is easy!</h2>
                        </div>
                    </div>
                    <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <div className="card me-lg-6">
                                <div className="card-body p-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <span className="icon btn btn-circle btn-lg btn-soft-dark pe-none me-4"><span className="number">01</span></span>
                                        </div>
                                        <div>
                                            <h4 className="mb-1 fs-17">Program Selection</h4>
                                            <p className="mb-0 fs-16">Carefully select any one of our educational offerings.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card ms-lg-13 mt-6">
                                <div className="card-body p-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <span className="icon btn btn-circle btn-lg btn-soft-dark pe-none me-4"><span className="number">02</span></span>
                                        </div>
                                        <div>
                                            <h4 className="mb-1 fs-17">Online Application</h4>
                                            <p className="mb-0 fs-16">Complete our online application form.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mx-lg-6 mt-6">
                                <div className="card-body p-4">
                                    <div className="d-flex flex-row">
                                        <div>
                                            <span className="icon btn btn-circle btn-lg btn-soft-dark pe-none me-4"><span className="number">03</span></span>
                                        </div>
                                        <div>
                                            <h4 className="mb-1 fs-17">Application Review</h4>
                                            <p className="mb-0 fs-16">Our programs team carefully reviews your application</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="display-6 mb-1">Here's How It Works</h2>
                            <p className="lead fs-md pe-lg-5 mb-3">Our admissions process is simple and user-friendly.</p>
                            <p className='fs-16 mb-2'>Begin by selecting the program that best matches your interests and career goals. Complete the online application form and submit any required materials. This form collects essential information such as personal details and educational background.</p>
                            <p className="mb-6">Our programs team will carefully review your application, considering your qualifications, personal statement, and entrance exam results (if an entrance examination is required for your program of choice).</p>
                            <a href="/admissions-process" className="btn btn-dark rounded mb-0 fs-15">Learn More About Our Admission Process</a>
                        </div>
                    </div>
                    {/* <div className="row gx-lg-8 gx-xl-10 align-items-center">
                        <div className="col-lg-6">
                            <figure>
                                <img
                                    src="https://res.cloudinary.com/dar3e312jder/image/upload/v1717383182/website/pexels-joshua-mcknight-442355-1139319_p994jx.jpg"
                                    srcSet="https://res.cloudinary.com/dar3e312jder/image/upload/v1717383182/website/pexels-joshua-mcknight-442355-1139319_p994jx.jpg 2x"
                                    alt="" className="rounded"
                                />
                            </figure>
                        </div>
                        <div className="col-lg-6">
                            <h2 className="fs-15 text-uppercase text-muted mb-3">How To Apply</h2>
                            <h3 className="display-4 mb-5">Applying for any of our educational programs is easy!</h3>
                            <p className="mb-8">
                                To apply for any of our programs, simply select your program of choice, fill the registration form and follow the step sthere in
                            </p>
                            <div className="row gy-6 gx-xxl-8 process-wrapper">
                                <div className="col-md-4">
                                    <img
                                        src="https://sandbox.elemisthemes.com/assets/img/icons/lineal/cloud-computing.svg"
                                        className="svg-inject icon-svg icon-svg-sm text-green mb-3"
                                        alt="" 
                                    />
                                    <h4 className="mb-1">1. Download</h4>
                                    <p className="mb-0">Nulla vitae elit libero elit non porta eget.</p>
                                </div>
                                <div className="col-md-4">
                                    <img
                                        src="https://sandbox.elemisthemes.com/assets/img/icons/lineal/smartphone-2.svg"
                                        className="svg-inject icon-svg icon-svg-sm text-red mb-3" alt="" 
                                    />
                                    <h4 className="mb-1">2. Set Profile</h4>
                                    <p className="mb-0">Nulla vitae elit libero elit non porta eget.</p>
                                </div>
                                <div className="col-md-4">
                                    <img
                                        src="https://sandbox.elemisthemes.com/assets/img/icons/lineal/rocket.svg"
                                        className="svg-inject icon-svg icon-svg-sm text-aqua mb-3" alt="" 
                                    />
                                    <h4 className="mb-1">3. Start</h4>
                                    <p className="mb-0">Nulla vitae elit libero elit non porta eget.</p>
                                </div>
                            </div> 
                        </div>
                    </div> */}
                </div>
            </section>

            <section className="wrapper bg-gray" style={{ position: "relative" }}>
                <div id='tb-50' className="container" style={{ position: "relative", top: "-9rem" }}>
                    <div className="card shadow-lg " style={{ zIndex: 3, position: "relative" }}>
                        <div className="row gx-0">
                            <div className="col-lg-6 image-wrapper rounded-top rounded-lg-start mobile bg-cover"
                                data-image-src="https://res.cloudinary.com/dar3e312jder/image/upload/v1724110716/website/20240819_105223_nptazl.jpg"
                                style={{ backgroundImage: "url(https://res.cloudinary.com/dar3e312jder/image/upload/v1724110716/website/20240819_105223_nptazl.jpg;)" }}
                            >
                            </div>
                            <div className="col-lg-6">
                                <div className="p-8 p-md-11 p-lg-11">
                                    <h2 className="fs-15 text-uppercase text-line text-primary mb-3">Trending Programs</h2>
                                    <h2 className="display-4 mb-3">Up To 90% Scholarship Available for Our Upcoming Program!</h2>
                                    <p className="lead fs-md">This program is designed for <strong className='text-navy'>data engineers</strong>, <strong className='text-navy'>frontend engineers</strong>, <strong className='text-navy'>technical writers</strong>, <strong className='text-navy'>data analysts</strong>, and <strong className='text-navy'>software engineers</strong> seeking to enhance their skills and career prospects.</p>
                                    <p>With a comprehensive curriculum featuring live classes 2x a week, each session lasting two hours - thirty minutes, our program focuses on bridging the gap between development and operations for effective data solution deployment and management.</p>
                                    <a href="https://tr.ee/CDV24BA" className=" btn btn-sm btn-dark me-sm-2 fs-15">Apply for the program</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};

export default OurInitiatives;
