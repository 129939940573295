import React from "react";
import {Card} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import ReviewPlayer from "../../../players/review-player";

const Faqs = () => {
    const faqData = [
        {
            question: 'Is registration open for males in any of our programs?',
            answer:
                'Yes! We enthusiastically invite all to join our programs regardless of their gender or identity',
        },
        {
            question: 'What is the tuition fee for the Academy Training Band?',
            answer:
                'The tuition fee is fixed at a subsidized amount of ₦15,000 (15 USD), which will be converted based on the applicant’s location',
        },
        {
            question: 'Is an entrance evaluation test required to join a Training Band?',
            answer:
                'Yes, an entrance evaluation test is required to join a Training Band',
        },
        {
            question: 'Do I need to pay to write a Codevixens entrance evaluation test?',
            answer:
                'No, there is no fee for taking the entrance evaluation test. Applicants are only required to make payment for tuition fees after passing the test.',
        },
    ];

    return (
        <section className="wrapper mb-15">
            <div className="container py-14 pt-md-16 pt-lg-0 pb-md-10">
                <div className="row">
                    <div className="col-xl-10 col-12 mx-auto">
                        <h2 className="fs-15 text-uppercase text-muted mb-3 text-center">FAQ</h2>
                        <h3 className="display-6 mb-10 px-lg-12 px-xl-15 text-center">We've compiled a list of frequently asked questions to provide you with instant answers and insights into our educational programs.</h3>
                        <div className="row">
                            <div className="col-lg-9 col-12 mx-auto">
                                <Accordion id="accordion-1" className="accordion-wrapper">
                                    {faqData.map((faq, index) => (
                                        <Accordion.Item className="card" key={index} eventKey={String(index)}>
                                            <Card.Header>
                                                <Accordion.Button bsPrefix="nil">{faq.question}</Accordion.Button>
                                            </Card.Header>
                                            <Accordion.Body className="card-body">
                                                <p>{faq.answer}</p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faqs;
