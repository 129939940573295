import { useContext } from 'react';
import { tutors_info } from '../../../../constants';
import { ReactComponent as Calendar } from '../../../../assets/img/icons/lineal/calendar.svg';
import { ReactComponent as Globe } from '../../../../assets/img/icons/lineal/geolocalization.svg';
import { ReactComponent as Clock } from '../../../../assets/img/icons/lineal/clock.svg';
import { ModalContext } from '../../../../utils/contexts';
import EventsImages from '../../event-details/includes/Carousel';

const HeroForSchool = () => {
    const { setOpenModal } = useContext(ModalContext);
    const Carousel = 'https://res.cloudinary.com/dar3e312jder/image/upload/v1694267233/website/women-happy.jpg';
    const imageUrls = [Carousel, Carousel, Carousel, Carousel];

    return (
        <section className="wrapper mt-10">
            <div className="container pb-4 pb-xl-4 pb-md-16">
                {/* <section className="mb-8 mx-auto pt-xl-5 background-image rounded">
                    <EventsImages imageUrls={imageUrls} />
                </section> */}
                <section className="py-1 mx-auto0" style={{ 'position': 'relative' }}>
                    <div className="row gx-0 gx-lg-8 gx-xl-12 gy-10 align-items-start justify-content-between">
                        <div className="col-lg-7 text-left ml-auto">
                            <div className='mx-auto mb-4'>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> Community Hour </p>
                                </div>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> Twitter Spaces </p>
                                </div>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> Training Band </p>
                                </div>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> IWD </p>
                                </div>
                                <div className="badge bg-primary rounded mx-1">
                                    <p className='m-0'> Graduation </p>
                                </div>
                            </div>

                            <h2 className="display-3 wrapper-border pb-4">Database Administration <br /> [Beginner Level]</h2>
                            <div className="row gy-3 mb-2 wrapper-border">
                                <div className="col-xl-5">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li className='px-1 location-img d-flex flex-row'>
                                            <Calendar />
                                            <div>
                                                <h6 className='mb-0'>Date</h6>
                                                <p className='mb-0'>Thursday, August 10</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-4">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li className='px-1 location-img d-flex flex-row align-items-center'>
                                            <Clock />
                                            <div>
                                                <h6 className='mb-0'>6pm - 8pm WAT</h6>
                                                <p className='mb-0'>2 Hours</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-3">
                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                        <li className='px-1 location-img d-flex flex-row'>
                                            <Globe />
                                            <div>
                                                <h6 className='mb-0'>Location</h6>
                                                <p className='mb-0'>Virtual</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <h3 className="display-6 mt-6 fs-20">Course Overview</h3>
                            <p className='mb-10'>Cras mattis consectetur purus sit amet fermentum. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Integer posuere erat a ante venenatis. Etiam porta sem malesuada magna mollis euismod. Aenean lacinia bibendum.</p>

                            <h3 className="display-6 mb-4">About this event</h3>
                            <p>This Pitch Fest will take place virtually using Zoom Webinars. Once you register you will receive the Zoom link to access the meeting!</p>
                            <p>To access the meeting it is recommended to use the link to Zoom instead of viewing from the Eventbrite website.</p>
                        </div>
                        <div className='col-lg-5 col-xl-4 px-3 ms-auto sticky-component'>
                            <div className="card card-body p-3 col-md-15 mx-0">
                                <div className="row px-3 justify-content-between align-items-center mb-5">
                                    <h6 className='d-inline col-4 fs-15 p-0'> Attendee </h6>
                                    <span className='d-inline-flex flex-row justify-content-end p-0 col-4 align-items-center'>
                                        <h6 className='my-0 fs-20 mx-3'> 1 </h6>
                                    </span>
                                </div>
                                <button className="btn btn-primary btn-sm"
                                    onClick={() => {
                                        setOpenModal(true);
                                        document.body.classList.add('modal-open');
                                    }}
                                > Register
                                </button>
                            </div>
                            <h3 className="display-6 fs-17 mt-6">What You Will Learn</h3>
                            <div className="accordion accordion-wrapper" id="accordionIconExample">
                                <div className="card accordion-item">
                                    <div className="card-header" id="headingIconOne">
                                        <button className="accordion-button collapsed fs-15" data-bs-toggle="collapse" data-bs-target="#collapseIconOne" aria-expanded="true" aria-controls="collapseIconOne">Introduction to DevOps</button>
                                    </div>
                                    <div id="collapseIconOne" className="accordion-collapse collapse" aria-labelledby="headingIconOne" data-bs-parent="#accordionIconExample">
                                        <div className="card-body">
                                            <p className="fs-14">Understand the key principles of DevOps, its importance in modern software development, and the cultural shift required for successful implementation.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* <div className='container'>
                <div className="row gx-lg-8 gx-xl-12 gy-10 mb-10 mb-md-12 align-items-center">
                    <div className="col-lg-9">
                        <h2 className="display-4 mb-3">Codevixens CloudDevOps School</h2>
                        <p className="lead fs-lg">We are a digital and branding company that believes in the power of creative strategy and along with great design.</p>
                        <p className="mb-6">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.</p>
                        <div className="row gy-3 gx-xl-8">
                            <div className="col-xl-6">
                                <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                    <li><span><i className="uil uil-check"></i></span><span>Aenean eu leo quam ornare curabitur blandit tempus.</span></li>
                                    <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Nullam quis risus eget urna mollis ornare donec elit.</span></li>
                                </ul>
                            </div>
                            <div className="col-xl-6">
                                <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                    <li><span><i className="uil uil-check"></i></span><span>Etiam porta sem malesuada magna mollis euismod.</span></li>
                                    <li className="mt-3"><span><i className="uil uil-check"></i></span><span>Fermentum massa vivamus faucibus amet euismod.</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center justify-content-lg-start" data-cues="slideInDown" data-group="page-title-buttons" data-delay="900">
                            <span><a className="btn btn-lg btn-primary rounded me-2">Get Started</a></span>
                            <span><a className="btn btn-lg btn-green rounded">Free Trial</a></span>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    )
}

export default HeroForSchool;