import React from 'react';

const JobOverview = () => {
    return (
        <section className="wrapper bg-light">
            <div className="container pb-14 pb-md-16">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="blog single mt-n17">
                            <div className="card shadow-lg">
                                <div className="card-body pt-6">
                                    <ul className="nav nav-tabs nav-tabs-basic">
                                        <li className="nav-item"> <a className="nav-link active" data-bs-toggle="tab" href="#tab3-1">Job Overview</a> </li>
                                        <li className="nav-item"> <a className="nav-link" data-bs-toggle="tab" href="#tab3-2">Application</a> </li>
                                    </ul>
                                    <div className="tab-content mt-0 mt-md-5">
                                        <div className="tab-pane fade show active" id="tab3-1">
                                            <h2 className="h6 mb-3">Job Description</h2>
                                            <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit tempus porttitor. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget porta ac consectetur vestibulum.</p>
                                            <p>Donec sed odio dui consectetur adipiscing elit. Etiam adipiscing tincidunt elit, eu convallis felis suscipit ut. Phasellus rhoncus tincidunt auctor. Nullam eu sagittis mauris. Donec non dolor ac elit aliquam tincidunt at at sapien. Aenean tortor libero, condimentum ac laoreet vitae, varius tempor nisi. Duis non arcu vel lectus urna mollis ornare vel eu leo.</p>
                                            <h3 className="h2 mb-3 mt-9">Responsibilities</h3>
                                            <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.</p>
                                            <div className="row gy-3 gx-xl-8">
                                                <div className="col-xl-6">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check"></i></span><span>Aenean eu leo quam ornare curabitur blandit tempus.</span></li>
                                                        <li><span><i className="uil uil-check"></i></span><span>Nullam quis risus eget urna mollis ornare donec elit.</span></li>
                                                    </ul>
                                                </div>
                                                <div className="col-xl-6">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check"></i></span><span>Etiam porta sem malesuada magna mollis euismod.</span></li>
                                                        <li><span><i className="uil uil-check"></i></span><span>Fermentum massa vivamus faucibus amet euismod.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <h3 className="h2 mb-3 mt-9">Requirements</h3>
                                            <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Praesent commodo cursus magna, vel scelerisque nisl consectetur et.</p>
                                            <div className="row gy-3 gx-xl-8 mb-10">
                                                <div className="col-xl-6">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check"></i></span><span>Aenean eu leo quam ornare curabitur blandit tempus.</span></li>
                                                        <li><span><i className="uil uil-check"></i></span><span>Nullam quis risus eget urna mollis ornare donec elit.</span></li>
                                                    </ul>
                                                </div>
                                                <div className="col-xl-6">
                                                    <ul className="icon-list bullet-bg bullet-soft-primary mb-0">
                                                        <li><span><i className="uil uil-check"></i></span><span>Etiam porta sem malesuada magna mollis euismod.</span></li>
                                                        <li><span><i className="uil uil-check"></i></span><span>Fermentum massa vivamus faucibus amet euismod.</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <a href="mailto:apply@email.com" className="btn btn-primary rounded-pill">Apply Now</a>
                                        </div>
                                        <div className="tab-pane fade" id="tab3-2">
                                            ...
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default JobOverview;
