import React from 'react';

const Header = () => {
    return (
        <>
            <section className="wrapper bg-gray position-relative">
                <div className="container pt-10 pb-17 pt-md-14 text-center">
                    <div className="row">
                        <div className="col-lg-8 mx-auto mb-5">
                            <h1 className="fs-15 text-uppercase text-muted mb-3">Join Our Team</h1>
                            <h3 className="display-1 mb-6">Join our team to help shape the future of development.</h3>
                            <a href="#" className="btn btn-lg btn-primary rounded-pill">Explore Positions</a>
                        </div>
                    </div>
                </div>
                <div className="overflow-hidden">
                    <div className="divider text-light mx-n2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 60">
                            <path fill="currentColor" d="M0,0V60H1440V0A5771,5771,0,0,1,0,0Z"></path>
                        </svg>
                    </div>
                </div>
            </section>
            <section className="wrapper bg-light">
                <div className="container pb-15 pb-md-17">
                    <div className="row gx-md-5 gy-5 mt-n18 mt-md-n19">
                        <div className="col-md-6 col-xl-3">
                            <div className="card lift">
                                <div className="card-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.1 256.01" data-inject-url="https://sandbox.elemisthemes.com/assets/img/icons/solid/target.svg" className="svg-inject icon-svg icon-svg-sm solid-mono text-primary mb-3"><path className="fill-secondary" d="M178.66 79.37L166.93 91.1a76.68 76.68 0 014.91 11.52 85.77 85.77 0 0114.93-1.28h1.39a93.65 93.65 0 00-9.5-21.97zM0 128a96.1 96.1 0 00109.65 95 82.93 82.93 0 01-5.65-15.42 67 67 0 01-8 .43A80 80 0 0196 48a79 79 0 0137 9.17l11.73-11.73A94.73 94.73 0 0096 32a96.14 96.14 0 00-96 96z"></path><path className="fill-secondary" d="M37.33 128A58.78 58.78 0 0096 186.68a47.26 47.26 0 005.44-.32A82.71 82.71 0 01103 170a37.32 37.32 0 01-7 .64 42.67 42.67 0 010-85.34 32.88 32.88 0 018 .84l12.91-12.91a57 57 0 00-20.91-4A58.81 58.81 0 0037.33 128z"></path><path className="fill-secondary" d="M96 106.68A21.33 21.33 0 10117.33 128 21.39 21.39 0 0096 106.68zm0 26.66a5.33 5.33 0 115.33-5.33 5.28 5.28 0 01-5.33 5.33z"></path><path className="fill-primary" d="M96.1 136a8 8 0 01-5.67-13.65L159.76 53a8 8 0 0111.31 11.31l-69.33 69.33A8 8 0 0196.1 136z"></path><path className="fill-primary" d="M194.77 64H168.1a8 8 0 01-8-8V29.34a8.08 8.08 0 012.33-5.67l21.33-21.33A8 8 0 01197.42 8v18.68h18.68a8 8 0 015.66 13.66l-21.33 21.33a8 8 0 01-5.66 2.33zm-8 53.34a69.34 69.34 0 1069.33 69.34 69.4 69.4 0 00-69.33-69.34zm29.87 56.11L182 210.78a8 8 0 01-11.09.64l-18.67-16a8 8 0 1110.41-12.15l12.84 11 29.44-31.69a8 8 0 0111.74 10.88z"></path></svg>
                                    <h4>Career Growth</h4>
                                    <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card lift">
                                <div className="card-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" data-inject-url="https://sandbox.elemisthemes.com/assets/img/icons/solid/videocall.svg" className="svg-inject icon-svg icon-svg-sm solid-mono text-primary mb-3"><g data-name="Layer 2"><path className="fill-secondary" d="M256 21.33v160a21.39 21.39 0 01-21.33 21.33H186a55.9 55.9 0 00-55.36-48H128V149a63.52 63.52 0 0016-22.08v27.73h96V29.33A13.33 13.33 0 00226.67 16h-69.33A13.33 13.33 0 00144 29.33v46.72a62.68 62.68 0 00-16-22.19V21.33A21.39 21.39 0 01149.33 0h85.33A21.4 21.4 0 01256 21.33z"></path><path className="fill-secondary" d="M192 85.33a16 16 0 1116-16 16 16 0 01-16 16zM216 128h-48a8 8 0 01-8-8 24 24 0 0124-24h16a24 24 0 0124 24 8 8 0 01-8 8z"></path><path className="fill-primary" d="M162.67 256H8a8 8 0 01-8-8v-37.33a40 40 0 0140-40h90.67a40 40 0 0140 40V248a8 8 0 01-8 8zM38.4 91.73a1.8 1.8 0 01.11-.75 48 48 0 0193.76.32c0 .11.11.32.11.43s-.32.11-.43.11C114 96.64 102.4 77.12 102 76.27a5.32 5.32 0 00-3.84-2.56 5.42 5.42 0 00-4.48 1.39c-22.62 21-52.81 17-55.26 16.63z"></path><path className="fill-primary" d="M126.08 103.25c-14.4 0-24.64-10-29.76-16.43-24.32 19.08-53 16.32-58.88 15.56a21.89 21.89 0 00.21 4.6 48 48 0 0095.36-.32 18.89 18.89 0 00.21-4.28 26.93 26.93 0 01-7.14.87z"></path></g></svg>
                                    <h4>Work From Anywhere</h4>
                                    <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card lift">
                                <div className="card-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 234.67" data-inject-url="https://sandbox.elemisthemes.com/assets/img/icons/solid/wallet.svg" className="svg-inject icon-svg icon-svg-sm solid-mono text-primary mb-3"><path className="fill-secondary" d="M184.21 42.75L161.57 4a8 8 0 00-4.91-3.76 8.1 8.1 0 00-6.12.9L81.31 42.66z"></path><path className="fill-primary" d="M32 53.33A10.67 10.67 0 0132 32h36l35.63-21.33H32A32 32 0 00.53 37.33 10.9 10.9 0 000 40v162.67a32.09 32.09 0 0032 32h181.33a21.39 21.39 0 0021.33-21.32V192H208a48 48 0 110-96h26.67V74.69a21.39 21.39 0 00-21.33-21.33H32zM202.67 32a21.3 21.3 0 00-18.45-21.12l18.45 31.79z"></path><path className="fill-secondary" d="M248 112h-40a32 32 0 000 64h40a8 8 0 008-8v-48a8 8 0 00-8-8zm-40 42.66A10.67 10.67 0 11218.67 144 10.68 10.68 0 01208 154.66z"></path></svg>
                                    <h4>Smart Salary</h4>
                                    <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-3">
                            <div className="card lift">
                                <div className="card-body">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.98 256" data-inject-url="https://sandbox.elemisthemes.com/assets/img/icons/solid/alarm.svg" className="svg-inject icon-svg icon-svg-sm solid-mono text-primary mb-3"><path className="fill-secondary" d="M26.64 256a10.66 10.66 0 01-8.26-17.4l23.33-28.67a10.67 10.67 0 1116.55 13.47l-23.33 28.67a10.65 10.65 0 01-8.29 3.93zm202.69 0a10.65 10.65 0 01-8.29-3.93l-23.33-28.67a10.67 10.67 0 0116.55-13.47l23.32 28.67a10.66 10.66 0 01-8.25 17.4zM10.66 72a10.66 10.66 0 01-7.54-18.2L53.79 3.13a10.67 10.67 0 0115.09 15.08L18.21 68.88A10.68 10.68 0 0110.66 72zm234.65 0a10.55 10.55 0 01-7.53-3.12L187.1 18.21a10.67 10.67 0 1115.09-15.08l50.67 50.67a10.66 10.66 0 01-7.55 18.2z"></path><path className="fill-primary" d="M128 32a112 112 0 10112 112A112.12 112.12 0 00128 32zm66.68 122.68H128A10.68 10.68 0 01117.31 144V80a10.68 10.68 0 0121.35 0v53.33h56a10.68 10.68 0 010 21.35z"></path></svg>
                                    <h4>Flexible Hours</h4>
                                    <p className="mb-0">Nulla vitae elit libero, a pharetra augue. Donec id elit non mi porta gravida at eget metus cras justo.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Header;
