import React from 'react';
import Header from './includes/Header';
import JobOverview from './includes/JobOverview';

const OpenPosition = () => {
    return (
        <div id='open-positions'>
            <Header />
            <JobOverview />
        </div>
    );
};

export default OpenPosition;
