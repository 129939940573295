import './../css/trainings.css'

const Overview = () => {
    return (
        <>
            <section className="wrapper mt-10">
                <div className='container'>
                    <div className="row">
                        <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                            <span className="badge bg-pale-violet text-violet rounded-pill mb-1">Codevixens Academy</span>
                            <h3 className="display-4 mb-">Codevixens Academy Schools: <br /> <i>set yourself up for long-term success</i></h3>
                        </div>
                        <div className="col-xl-6 d-flex justify-content-center align-items-center">
                            <div>
                                <h3 className="h4 mb-0 mb-lg-0">Academic Resources</h3>
                                <div className="row gx-3 pt-2">
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <a href="/admissions-process" className="btn btn-outline-navy btn-sm">Admission Process</a>
                                        </div>
                                        <div>
                                            <a href="/admissions-policy" className="btn btn-outline-navy btn-sm w-100 justify-content-start">Admission Policy</a>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <a href="academic-program.html" className="btn btn-outline-navy btn-sm">Financial Assistance</a>
                                        </div>
                                        <div>
                                            <a href="academic-program.html" className="btn btn-outline-navy btn-sm w-100 justify-content-start">Academic Calendar</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="mb-3">
                        Welcome to Codevixens Academy Schools, an exciting new initiative designed to provide specialized learning pathways for aspiring tech professionals. Our schools are crafted to meet the diverse needs of our community, offering targeted courses that align with current industry demands. Whether you’re just starting your tech journey or looking to advance your skills, Codevixens Academy Schools offer a structured, supportive environment to help you achieve your career goals.
                    </p>
                    <ul className="icon-list bullet-bg bullet-soft-green">
                        <li><span><i className="uil uil-check"></i></span><span>100% Online Training</span></li>
                        <li><span><i className="uil uil-check"></i></span><span>List item.</span></li>
                        <li><span><i className="uil uil-check"></i></span><span>Certificate of Completion</span></li>
                        <li><span><i className="uil uil-check"></i></span><span>List item.</span></li>
                    </ul>

                    <div className="accordion accordion-wrapper mt-4 pt-6" id="accordionIconExample">
                        <h3 className="mb-3 h4">Our Academy Schools</h3>
                        <div className="card accordion-item icon">
                            <div className="card-header" id="headingIconOne">
                                <button
                                    className="accordion-button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseIconOne"
                                    // aria-expanded="true"
                                    aria-controls="collapseIconOne"
                                ><span><i className="uil uil-check-circle"></i></span>CloudDevOps School<span className="badge bg-primary rounded-pill mb-0 position-relative ms-2">Guaranteed Tuition Fee Discounts</span>
                                </button>
                            </div>
                            {/* <!--/.card-header --> */}
                            <div id="collapseIconOne" className="accordion-collapse collapse" aria-labelledby="headingIconOne" data-bs-parent="#accordionIconExample">
                                <div className="card-body">
                                    <h3 className="display-6 mt-1 fs-17">Courses List</h3>
                                    <ul className="icon-list bullet-bg bullet-soft-dark">
                                        <li><span><i className="uil uil-arrow-right"></i></span><span>DevOps Engineering</span></li>
                                        {/* <li><span><i className="uil uil-check"></i></span><span>Cloud Engineering (Beginner) Course</span></li> */}
                                        {/* <li><span><i className="uil uil-check"></i></span><span>DevOps Engineering (Intermediate) Course</span></li> */}
                                        <li><span><i className="uil uil-arrow-right"></i></span><span>Cloud Engineering</span></li>
                                    </ul>

                                    <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel.</p>

                                    <h3 className="display-6 mt-1 fs-17">Who Should Enroll in Our CloudDevOps School?</h3>
                                    <hr className="mt-2 mb-3" />
                                    <section>
                                        <h2 className="fs-15 text-uppercase text-line text-navy">Frontend Engineers</h2>
                                        <p>
                                            It’s no news that the field of frontend engineering is heavily saturated.
                                            With so many people entering tech through HTML & CSS, it’s become a highly competitive space.
                                            As the industry evolves and the demand for advanced skills grows, staying ahead requires continuous upskilling.
                                            This is particularly important given the recent wave of layoffs in tech, both locally and globally.
                                        </p>
                                    </section>
                                    <section>
                                        <h2 className="fs-15 text-uppercase text-line text-navy">Backend Engineers</h2>
                                        <p>
                                            For backend engineers, understanding DevOps and cloud services is crucial for career growth.
                                            The backend development field demands a strong grasp of server infrastructure and deployment processes.
                                            By learning DevOps practices and cloud computing, backend engineers can streamline development workflows, improve deployment efficiency, and enhance system reliability.
                                        </p>
                                    </section>
                                    <section>
                                        <h2 className="fs-15 text-uppercase text-line text-navy">Technical Writers</h2>
                                        <p>
                                            The field of technical writing is vast, and for those in tech-focused companies, understanding DevOps and cloud technologies is becoming increasingly important.
                                            Technical writers often need to document DevOps-related software, tools, and internal processes.
                                            In fact, there is a growing demand for freelance technical writers with extensive knowledge of DevOps, cloud computing, Kubernetes, and related technologies.
                                            By gaining these skills, technical writers can produce more accurate and comprehensive documentation, making them invaluable assets to their teams.
                                        </p>
                                    </section>
                                    <section>
                                        <h2 className="fs-15 text-uppercase text-line text-navy">Other Tech Professionals</h2>
                                        <p>
                                            While our primary focus is on frontend, backend engineers, and technical writers, our CloudDevOps School is also ideal for other tech professionals looking to expand their skill set.
                                            Whether you’re a QA engineer, system administrator, or IT support specialist, understanding DevOps and cloud technologies will enhance your ability to contribute to your organization’s success.
                                            These skills are invaluable for anyone involved in software development, deployment, or IT operations.
                                        </p>
                                    </section>

                                    <h3 className="display-6 mt-1 fs-17">What Enrolling in Our CloudDevOps School Helps You Achieve</h3>
                                    <hr className="mt-2 mb-3" />
                                    <p className='mt-0'>Enrolling in our CloudDevOps School can help you achieve several career aspirations and goals, including:</p>
                                    <section>
                                        <h2 className="fs-15 text-uppercase text-line text-navy fw-bold">Career Advancement</h2>
                                        <p>
                                            Gain the skills needed to move into more senior roles, such as DevOps Engineer, Cloud Engineer, Site Reliability Engineer, Technical Documentation Engineer or Platform Engineer.
                                        </p>
                                    </section>
                                </div>
                                {/* <!--/.card-body --> */}
                            </div>
                            {/* <!--/.accordion-collapse --> */}
                        </div>
                        {/* <!--/.accordion-item --> */}
                        <div className="card accordion-item icon">
                            <div className="card-header" id="headingIconTwo">
                                <button className="collapsed" data-bs-toggle="collapse" data-bs-target="#collapseIconTwo" aria-expanded="false" aria-controls="collapseIconTwo"><span><i className="uil uil-check-circle"></i></span>Data Engineering School</button>
                            </div>
                            {/* <!--/.card-header --> */}
                            <div id="collapseIconTwo" className="accordion-collapse collapse" aria-labelledby="headingIconTwo" data-bs-parent="#accordionIconExample">
                                <div className="card-body">
                                    <p>Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Cras mattis consectetur purus sit amet fermentum. Praesent commodo cursus magna, vel.</p>
                                </div>
                                {/* <!--/.card-body --> */}
                            </div>
                            {/* <!--/.accordion-collapse --> */}
                        </div>
                        {/* <!--/.accordion-item --> */}
                    </div>

                    <div className="bands-list mt-10"><div className="col-xl-10 mx-auto text-center">
                        <h2 className="fs-15 text-uppercase text-muted mb-3">Job Positions</h2>
                        <h3 className="display-4 mb-10 px-xxl-15">We’re always searching for amazing people to join our team. Take a look at our current openings.</h3>
                    </div><div className="job-list mb-10">
                            <h3 className="mb-4">Batch B</h3>
                            <a href="#" className="card mb-4 lift">
                                <div className="card-body p-5">
                                    <span className="row justify-content-between align-items-center">
                                        <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                                            <span className="avatar bg-red text-white w-9 h-9 fs-17 me-3">GD</span> Cloud Engineering Beginner </span>
                                        <span className="col-5 col-md-3 text-body d-flex align-items-center">
                                            <i className="uil uil-clock me-1"></i> 7 Weeks </span>
                                        <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                                            <i className="uil uil-location-arrow me-1"></i> 7 Weeks </span>
                                        <span className="d-none d-lg-block col-1 text-center text-body">
                                            <i className="uil uil-angle-right-b"></i>
                                        </span>
                                    </span>
                                </div>
                            </a>
                            <a href="#" className="card mb-4 lift">
                                <div className="card-body p-5">
                                    <span className="row justify-content-between align-items-center">
                                        <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                                            <span className="avatar bg-green text-white w-9 h-9 fs-17 me-3">UX</span> UI/UX Designer </span>
                                        <span className="col-5 col-md-3 text-body d-flex align-items-center">
                                            <i className="uil uil-clock me-1"></i> Remote </span>
                                        <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                                            <i className="uil uil-location-arrow me-1"></i> Anywhere </span>
                                        <span className="d-none d-lg-block col-1 text-center text-body">
                                            <i className="uil uil-angle-right-b"></i>
                                        </span>
                                    </span>
                                </div>
                            </a>
                            <a href="#" className="card mb-4 lift">
                                <div className="card-body p-5">
                                    <span className="row justify-content-between align-items-center">
                                        <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                                            <span className="avatar bg-yellow text-white w-9 h-9 fs-17 me-3">AN</span> Multimedia Artist &amp; Animator </span>
                                        <span className="col-5 col-md-3 text-body d-flex align-items-center">
                                            <i className="uil uil-clock me-1"></i> Full time </span>
                                        <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                                            <i className="uil uil-location-arrow me-1"></i> Birmingham, UK </span>
                                        <span className="d-none d-lg-block col-1 text-center text-body">
                                            <i className="uil uil-angle-right-b"></i>
                                        </span>
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div className="job-list mb-10">
                            <h3 className="mb-4">Data Engineering School</h3>
                            <a href="#" className="card mb-4 lift">
                                <div className="card-body p-5">
                                    <span className="row justify-content-between align-items-center">
                                        <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                                            <span className="avatar bg-red text-white w-9 h-9 fs-17 me-3">GD</span> Senior Graphic Designer </span>
                                        <span className="col-5 col-md-3 text-body d-flex align-items-center">
                                            <i className="uil uil-clock me-1"></i> Full time </span>
                                        <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                                            <i className="uil uil-location-arrow me-1"></i> San Francisco, US </span>
                                        <span className="d-none d-lg-block col-1 text-center text-body">
                                            <i className="uil uil-angle-right-b"></i>
                                        </span>
                                    </span>
                                </div>
                            </a>
                            <a href="#" className="card mb-4 lift">
                                <div className="card-body p-5">
                                    <span className="row justify-content-between align-items-center">
                                        <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                                            <span className="avatar bg-green text-white w-9 h-9 fs-17 me-3">UX</span> UI/UX Designer </span>
                                        <span className="col-5 col-md-3 text-body d-flex align-items-center">
                                            <i className="uil uil-clock me-1"></i> Remote </span>
                                        <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                                            <i className="uil uil-location-arrow me-1"></i> Anywhere </span>
                                        <span className="d-none d-lg-block col-1 text-center text-body">
                                            <i className="uil uil-angle-right-b"></i>
                                        </span>
                                    </span>
                                </div>
                            </a>
                            <a href="#" className="card mb-4 lift">
                                <div className="card-body p-5">
                                    <span className="row justify-content-between align-items-center">
                                        <span className="col-md-5 mb-2 mb-md-0 d-flex align-items-center text-body">
                                            <span className="avatar bg-yellow text-white w-9 h-9 fs-17 me-3">AN</span> Multimedia Artist &amp; Animator </span>
                                        <span className="col-5 col-md-3 text-body d-flex align-items-center">
                                            <i className="uil uil-clock me-1"></i> Full time </span>
                                        <span className="col-7 col-md-4 col-lg-3 text-body d-flex align-items-center">
                                            <i className="uil uil-location-arrow me-1"></i> Birmingham, UK </span>
                                        <span className="d-none d-lg-block col-1 text-center text-body">
                                            <i className="uil uil-angle-right-b"></i>
                                        </span>
                                    </span>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div className='mt-9 row'>
                        <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-4">
                            <div className="player" data-plyr-provider="vimeo" data-plyr-embed-id="15801179"></div>
                        </div>
                        <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-4">
                            <div className="player" data-plyr-provider="vimeo" data-plyr-embed-id="15801179"></div>
                        </div>
                        <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-4">
                            <div className="player" data-plyr-provider="vimeo" data-plyr-embed-id="15801179"></div>
                        </div>
                    </div>
                    {/* <!--/.accordion --> */}
                </div >
            </section>
        </>
    );
}

export default Overview;
