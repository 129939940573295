import React from 'react';

const Header = () => {
    return (
        <section className="wrapper">
            <div className="container pt-10 pb-19 pt-md-14 pb-md-20 text-center">
                <div className="row">
                    <div className="col-md-10 col-xl-8 mx-auto">
                        <div className="post-header">
                            <h1 className="display-1 mb-5">Multimedia Artist &amp; Animator</h1>
                            <ul className="post-meta fs-17 mb-5">
                                <li><i className="uil uil-clock"></i> Full time</li>
                                <li><i className="uil uil-location-arrow"></i> Manchester, UK</li>
                                <li><i className="uil uil-building"></i> Design Department</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;
