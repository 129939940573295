import React from 'react';

const Fellowship = () => {
    return (
        <div id='fellowship-page'>
            <section className="wrapper bg-gray position-relative min-vh-60 d-lg-flex align-items-center">
                <div className="col-lg-6 position-lg-absolute top-0 start-0 image-wrapper bg-image bg-cover h-100" data-image-src="https://res.cloudinary.com/dar3e312jder/image/upload/v1714157431/website/2149126949_bjp7f3.jpg" style={{ backgroundImage: 'url(&quot;https://res.cloudinary.com/dar3e312jder/image/upload/v1714157431/website/2149126949_bjp7f3.jpg&quot;)'}}>
                    <a href="https://sandbox.elemisthemes.com/assets/media/movie3.mp4" className="btn btn-circle btn-dark btn-play ripple mx-auto position-absolute d-lg-none" style={{top:'50%', left:' 50%', transform: 'translate(-50%,-50%)', zIndex:3}} data-glightbox="" data-gallery="mobile-video"><i className="icn-caret-right"></i></a>
                </div>
                <div className="container position-relative" data-cue="fadeIn" data-delay="600" data-show="true" style={{animationName: 'fadeIn', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '600ms', animationDirection: 'normal', animationFillMode: 'both'}}>
                    <a href="https://sandbox.elemisthemes.com/assets/media/movie3.mp4" className="btn btn-circle btn-dark btn-play ripple mx-auto position-absolute d-none d-lg-flex" style={{ top: '50%', left: ' 50%', transform: 'translate(-50%,-50%)', zIndex: 3 }} data-glightbox="" data-gallery="desktop-video"><i className="icn-caret-right"></i></a>
                    <div className="row gx-0">
                        <div className="col-lg-6 offset-lg-6">
                            <div className="py-10 py-lg-10 ps-lg-12 py-xxl-10 ps-xxl-14 pe-lg-0 position-relative" data-cues="slideInDown" data-group="page-title" data-disabled="true">
                                <h1 className="display-1 ls-xs fs-40 mb-4" data-cue="slideInDown" data-group="page-title" data-show="true" style={{animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both'}}>Welcome to <br/><span className="underline-3 style-2 yellow">Codevixens</span> Fellowship.</h1>
                                <p className="lead fs-18 lh-sm mb-7 me-16" data-cue="slideInDown" data-group="page-title" data-show="true" style={{ animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>An intensive 6-week journey from classroom to career, designed to equip you with the practical skills and hands-on experience necessary to excel in the tech industry.</p>
                                <div data-cue="slideInDown" data-group="page-title" data-show="true" style={{ animationName: 'slideInDown', animationDuration: '700ms', animationTimingFunction: 'ease', animationDelay: '0ms', animationDirection: 'normal', animationFillMode: 'both' }}>
                                    <a className="btn btn-md btn-dark rounded mb-2">Enrol into our Fellowship Program</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Fellowship;