import React from 'react';
import './css/support.css';
import '../../assets/css/plugins.css';
import ContactUsForm from './ContactUsForm';

const Support = () => {
	return (
		<>
			<button
				id="support"
				data-bs-toggle="modal"
				data-bs-target="#modal-02"
				data-bs-backdrop={false}
				className="animation-default animation-delay-1500 btn btn-lg bg-dark position-fixed fw-normal text-white rounded btn-outline-dark p-sm-2 p-1"
			>
				<span className="ms-sm-2 ms-1 ls-md fs-14">
					<p className="mb-0 fw-bold">Have Any Questions?</p>
					<p className="mb-0">Say Hi! We are available to help.</p>
				</span>
				<span className="badge bg-white p-1 rounded-circle ms-3">
					<i className="uil uil-question fs-20 text-dark"></i>
				</span>
			</button>

			{/* Contact by email and social media handles */}
			<div className="modal fade" id="modal-02" tabIndex="-1">
				<div className="modal-dialog  modal-dialog-centered modal-md">
					<div className="modal-content bg-gradient">
						{/* Reach by the contact us form */}
						<div className="modal-body text-center px-sm-10 px-5 pb-6">
							<h4>We'd Love to Hear From You!</h4>
							<p className="mb-0">
								Reach out to us through the form below, <br />
								and we'll get back to you as soon as possible.
							</p>

							{/*<a
								href="mailto:programs@codevixens.org"
								className="text-navy fw-bold"
							>
								programs@codevixens.org
							</a>
							<p className="mb-0 mt-2">
								You can also contact us with our social media handles
							</p>
							<nav className="social">
								<a
									href="https://twitter.com/codevixens"
									target="_blank"
									rel="noreferrer"
								>
									<i className="uil uil-twitter"></i>
								</a>
								<a
									href="https://www.facebook.com/people/Codevixens-Academy/100088290937780"
									target="_blank"
									rel="noreferrer"
								>
									<i className="uil uil-facebook-f"></i>
								</a>
								<a
									href="https://www.linkedin.com/company/codevixens-academy"
									target="_blank"
									rel="noreferrer"
								>
									<i className="uil uil-linkedin"></i>
								</a>
								<a
									href="https://www.instagram.com/codevixenshq"
									target="_blank"
									rel="noreferrer"
								>
									<i className="uil uil-instagram"></i>
								</a>
								<a
									href="https://www.youtube.com/@codevixensacademy"
									target="_blank"
									rel="noreferrer"
								>
									<i className="uil uil-youtube"></i>
								</a>
							</nav> */}
						</div>

						<ContactUsForm />
					</div>
				</div>
			</div>
		</>
	);
};

export default Support;
