import How from "./academy/includes/How";
import Overview from "./academy/includes/Overview";

const Academy = () => {
    return (
        <section className="wrapper">
            <Overview />
            <How />
            {/* <Tutors /> */}
        </section>
    );
}

export default Academy;