import React, { useEffect, useMemo, useState } from 'react';

import { Route, Routes } from 'react-router-dom';

import ErrorPage from './components/ErrorPage';
import Home from './components/pages/home/Home';
import Team from './components/pages/team/Team';
import { ModalContext } from './utils/contexts';
import Academy from './components/pages/Academy';
import Footer from './components/includes/Footer';
import Header from './components/includes/Header';
import Support from './components/includes/Support';
import Events from './components/pages/events/Events';
import AboutUs from './components/pages/about/AboutUs';
import Fellowship from "./components/pages/Fellowship";
import Testimonials from "./components/pages/Testimonials";
import Community from './components/pages/community/Community';
import AdmissionPolicy from './components/pages/admissions/Policy';
import AdmissionProcess from './components/pages/admissions/Process';
import EventDetails from './components/pages/event-details/EventDetails';
import ScrollToHashElement from './components/includes/ScrollToHashElement';
import FinancialAssistance from './components/pages/about/FinancialAssistance';
import AcademySchools from './components/pages/academy-schools/AcademySchools';
import EventRegistration from './components/pages/event-details/includes/EventRegistration';
import OpenPositions from './components/pages/open-positions/OpenPositions';
import OpenPosition from './components/pages/open-position/OpenPosition';

import './assets/scss/style.scss';


function setExternalScripts(url) {
    url.forEach(function (externalScript, key) {
        const script = document.createElement('script');
        script.src = externalScript['url'];
        externalScript['attribute'] === 'async'
            ? (script.async = true)
            : (script.defer = true);
        document.body.appendChild(script);
    });
}

function App() {
    //Context for registration modal
    const [openModal, setOpenModal] = useState(false);

    const modalValue = useMemo(() => {
        return { openModal, setOpenModal };
    }, [openModal, setOpenModal]);

    useEffect(() => {
        const onPageLoad = () => {
            setExternalScripts([
                { url: '/assets/js/theme.js', attribute: 'defer' },
            ]);
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return (
        <>
            <ModalContext.Provider value={modalValue}>
                <EventRegistration />
                <div className='content-wrapper'>
                    <Header />
                    <ScrollToHashElement />
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='/team' element={<Team />} />
                        <Route path='/careers/open-positions' element={<OpenPositions />} />
                        <Route path='/careers/open-positions/single' element={<OpenPosition />} />
                        <Route path='/fellowship' element={<Fellowship />} />
                        <Route path='/testimonials' element={<Testimonials />} />
                        <Route path='/financial-assistance' element={<FinancialAssistance />} />
                        <Route path='/admissions-policy' element={<AdmissionPolicy />} />
                        <Route path='/admissions-process' element={<AdmissionProcess />} />
                        <Route path='/about-us' element={<AboutUs />} />
                        <Route path='/academy-program' element={<Academy />} />
                        <Route path='/academy-program/schools' element={<AcademySchools />} />
                        <Route path='/community' element={<Community />} />
                        <Route path='/events' element={<Events />} />
                        <Route path='/events/1' element={<EventDetails />} />
                        <Route path='*' element={<ErrorPage />} />
                    </Routes>
                </div>
                <Support />
                <Footer />
            </ModalContext.Provider>
            {setExternalScripts([
                { url: '/assets/js/plugins.js', attribute: 'defer' },
            ])}
        </>
    );
}

export default App;
